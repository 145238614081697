import {
    registerPageTab,
} from '@vendure/admin-ui/core';

import { CustomerFavoritesListComponent } from './components/product-favorites-list/customer-favorites-list.component'

export default [
    registerPageTab({
        location: 'customer-detail',
        route: 'favorites',
        tab: 'Favorites',
        tabIcon: 'heart',
        component: CustomerFavoritesListComponent,
    }),
];
