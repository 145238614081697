export const extensionRoutes = [  {
    path: 'extensions/vendor',
    loadChildren: () => import('./extensions/multivendor-ui/routes'),
  },
  {
    path: 'extensions/product-reviews',
    loadChildren: () => import('./extensions/d3d876fabe1b14f1102e3ab7d1b20f3848a172effc86be6aa92db0faad03ebcb/routes'),
  },
  {
    path: 'extensions/favorites',
    loadChildren: () => import('./extensions/favorites-ui/routes'),
  }];
