
<vdr-data-table-2
        id="customer-favorite-list"
        [items]="items$ | async"
        [itemsPerPage]="itemsPerPage$ | async"
        [totalItems]="totalItems$ | async"
        [currentPage]="currentPage$ | async"
        [filters]="filters"
        (pageChange)="setPageNumber($event)"
        (itemsPerPageChange)="setItemsPerPage($event)"
>
    <vdr-dt2-column 
        [heading]="'common.id' | translate" 
        [hiddenByDefault]="true"
    >
        <ng-template let-favorite="item">{{ favorite.id }}</ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column
            [heading]="'common.created-at' | translate"
            [hiddenByDefault]="true"
    >
        <ng-template let-favorite="item">
            {{ favorite.createdAt | localeDate : 'short' }}
        </ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column heading="Product">
        <ng-template let-favorite="item">{{ favorite.product.name }}</ng-template>
    </vdr-dt2-column>
    <vdr-dt2-column heading="Product" >
        <ng-template let-favorite="item">
            <a class="button-ghost" [routerLink]="['/catalog', 'products', favorite.product.id]"
            ><span>{{ favorite.product.name }}</span>
                <clr-icon shape="arrow right"></clr-icon>
            </a></ng-template>
    </vdr-dt2-column>
</vdr-data-table-2>
