import { Component, ChangeDetectionStrategy, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    BaseListComponent,
    DataService,
    DataTableFilterCollection,
    DataTableSortCollection,
    ItemOf,
    SharedModule,
} from '@vendure/admin-ui/core';

import gql from 'graphql-tag';
import { Observable } from 'rxjs';

import {
    GetCustomerFavoritesDocument,
    GetCustomerFavoritesQuery,
    GetCustomerFavoritesQueryVariables,
    CustomerFavoritesFilterParameter,
    CustomerFavoritesSortParameter,
    GetCustomerQuery,
} from '../../gql/graphql';


export const GET_CUSTOMER_FAVORITES = gql`
    query GetCustomerFavorites($customerId: ID!, $options: FavoriteListOptions, $productNameFilter: String) {
        customer(id: $customerId) {
            id
            favorites(options: $options, productNameFilter: $productNameFilter) {
                items {
                id
                createdAt
                product {
                    id
                    name
                    featuredAsset {
                        id
                        preview
                        focalPoint {
                            x
                            y
                        }
                    }
                }
                }
                totalItems
            }
        }
    }
`;

export const GET_CUSTOMER = gql`
    query GetCustomer($customerId: ID!) {
        customer(id: $customerId) {
            id
            firstName
            lastName
            emailAddress
        }
    }
`;

@Component({
    selector: 'customer-favorites-list',
    templateUrl: './customer-favorites-list.component.html',
    styleUrls: ['./customer-favorites-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [SharedModule],
})
export class CustomerFavoritesListComponent
    extends BaseListComponent<
        GetCustomerFavoritesQuery,
        ItemOf<NonNullable<GetCustomerFavoritesQuery['customer']>, 'favorites'>,
        GetCustomerFavoritesQueryVariables
    >
    implements OnInit {

    customer$: Observable<GetCustomerQuery['customer'] | null>;

    // Here we set up the filters that will be available
    // to use in the data table
    readonly filters = new DataTableFilterCollection<CustomerFavoritesFilterParameter>(this.router)
        .addFilter({
            name: 'productNameFilter',
            type: { kind: 'text' },
            label: 'Product Name',
            filterField: 'productNameFilter',
        })
        .connectToRoute(this.route);

    // readonly sorts = new DataTableSortCollection<CustomerFavoritesSortParameter>(this.router)
    //     .defaultSort('productName', 'DESC')
    //     .addSort({ name: 'productName' })
    //     .connectToRoute(this.route);

    constructor(private dataService: DataService, protected router: Router, route: ActivatedRoute) {
        super(router, route);
        super.setQueryFn(
            (...args: any) => {
                return this.dataService.query(GetCustomerFavoritesDocument, args);
            },
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            data => data.customer!.favorites,
            (skip, take) => ({
                customerId: route.snapshot.parent!.paramMap.get('id')!,
                options: {
                    skip,
                    take,
                },
                // productNameFilter: this.filters.createFilterInput() as string,
            }),
        );
    }

}
