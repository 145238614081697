import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import SharedProviders_0_0 from './extensions/multivendor-ui/providers';
import SharedProviders_1_0 from './extensions/d3d876fabe1b14f1102e3ab7d1b20f3848a172effc86be6aa92db0faad03ebcb/providers';
import SharedProviders_2_0 from './extensions/favorites-ui/providers';


@NgModule({
    imports: [CommonModule, ],
    providers: [...SharedProviders_0_0, ...SharedProviders_1_0, ...SharedProviders_2_0],
})
export class SharedExtensionsModule {}
